import React from "react";
import Combobox from "react-widgets/Combobox";
import { getDistrictsList, getAssemblyConstituencyList, getBlockList, getGramPanchayatList, SendOTP, getVerifyOTP, profielImageUpload, getSelfDetails, mediaURL, updateSelfMembershipData } from './../services/Voters';
import { Link , useNavigate} from "react-router-dom";
import withParams from "./GetParams";
import profileImagePlaceholder from '../assets/img/profile-image-placeholder-01.png';
import $ from "jquery";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Cropper from 'react-easy-crop';


function blobToDataURL(blob, callback) {
    var a = new FileReader();
    a.onload = function(e) {callback(e.target.result);}
    a.readAsDataURL(blob);
}

function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation)
    
    return {
        width:
        Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
        Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
}

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

async function createImage (url) {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    });
}

async function getCroppedImg(imageSrc, pixelCrop) {
    let rotation = 0;
    let flip = { horizontal: false, vertical: false }
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    
    if (!ctx) {
        return null
    }

    const rotRad = getRadianAngle(rotation)
    
    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    )

    
    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight
    
    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)
    
    // draw rotated image
    ctx.drawImage(image, 0, 0)

    
    const croppedCanvas = document.createElement('canvas')
    
    const croppedCtx = croppedCanvas.getContext('2d')
    
    if (!croppedCtx) {
        return null
    }
    
    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width
    croppedCanvas.height = pixelCrop.height
    
    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    )
    
    // As Base64 string
    // return croppedCanvas.toDataURL('image/jpeg');
    
    // As a blob
    return new Promise((resolve, reject) => {
        croppedCanvas.toBlob((file) => {
          resolve(file)
        }, 'image/jpeg')
      })

}

class SelfUserProfileEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            districts: [
                {
                  "id": 51,
                  "number": null,
                  "name": "Angul",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 55,
                  "number": null,
                  "name": "Balasore",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 80,
                  "number": null,
                  "name": "Bargarh",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 68,
                  "number": null,
                  "name": "Bhadrak",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 52,
                  "number": null,
                  "name": "Bolangir",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 53,
                  "number": null,
                  "name": "Boudh",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 76,
                  "number": null,
                  "name": "Cuttack",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 63,
                  "number": null,
                  "name": "Deogarh",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 62,
                  "number": null,
                  "name": "Dhenkanal",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 56,
                  "number": null,
                  "name": "Gajapati",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 54,
                  "number": null,
                  "name": "Ganjam",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 66,
                  "number": null,
                  "name": "Jagatsinghpur",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 71,
                  "number": null,
                  "name": "Jajpur",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 74,
                  "number": null,
                  "name": "Jharsuguda",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 69,
                  "number": null,
                  "name": "Kalahandi",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 65,
                  "number": null,
                  "name": "Kandhamal",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 67,
                  "number": null,
                  "name": "Kendrapara",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 73,
                  "number": null,
                  "name": "Keonjhar",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 60,
                  "number": null,
                  "name": "Khordha",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 59,
                  "number": null,
                  "name": "Koraput",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 78,
                  "number": null,
                  "name": "Malkanagiri",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 72,
                  "number": null,
                  "name": "Mayurbhanj",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 77,
                  "number": null,
                  "name": "Nabarangpur",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 70,
                  "number": null,
                  "name": "Nayagarh",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 75,
                  "number": null,
                  "name": "Nuapada",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 61,
                  "number": null,
                  "name": "Puri",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 64,
                  "number": null,
                  "name": "Rayagada",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 58,
                  "number": null,
                  "name": "Sambalpur",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 57,
                  "number": null,
                  "name": "Subarnpur",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 79,
                  "number": null,
                  "name": "Sundargarh",
                  "state": 1,
                  "slug": null
                }
              ],
            districtInputValue: null,
            constituencyList: [],
            constituencyInputValue: null,
            grampanchayatList: [],
            grampanchayatInputValue: null,
            firstName: null,
            fathersName: null,
            age: null,
            gender: null,
            wardNumber: null,
            voterCardNumber: null,
            whatsAppNumber: null,
            submitStatus: false,
            occupation: null,
            otpSentStatus: null,
            OTPVerificationStatus: false,
            OTPNumber: null,
            setUploadProgress: 0,
            mobileNumberErrorMessage: null,
            districtInputValueName: null,
            constituencyInputValueName: null,
            grampanchayatInputValueName: null,
            districtInputValueId: null,
            constituencyInputValueId: null,
            grampanchayatInputValueId: null,
            profileImage: null,
            primaryDNDCheck: true,
            whatsAppNumberErrorMessage: null,
            formError: null,
            isLoading: false,
            studentCollegeName: '',
            studentCollegeNameErrorMessage: null,
            isUrban: false,
            isUserStudent: null,
            profilePhoto: null,
            profileImageURL: null,
            imageSrc: '',
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 1 / 1,
            imageCroppedArea: 0,
            imageCroppedAreaPixels: 0,
            dataUrlImage: null,
            uploadedFileName: '',
            originalFileName: 'Click your photo'
        };
        this.handleFirstName = this.handleFirstName.bind(this);
        this.handleProfileImage = this.handleProfileImage.bind(this);
        this.handleMemberShipSubmit = this.handleMemberShipSubmit.bind(this);
        this.handleCroppedImage = this.handleCroppedImage.bind(this);
        this.updateUploadProgress = this.updateUploadProgress.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleProfilePhotos = this.handleProfilePhotos.bind(this);
        this.handleFathersName = this.handleFathersName.bind(this);
        this.handleAge = this.handleAge.bind(this);
        this.handleGender = this.handleGender.bind(this);
        this.handleVoterCardNumber = this.handleVoterCardNumber.bind(this);
    }

    scrollTo(elementId) {
        $('html, body').animate({
            scrollTop: $(elementId).offset().top - 100
        }, 50);
    }

    handleFirstName(event) {
        this.setState({firstName: event?.target?.value, firstNameErrorMessage: null});
    }

    handleFathersName(event) {
        this.setState({fathersName: event?.target?.value, fathersNameErrorMessage: null});
    }

    handleAge(event) {
        const age = event?.target?.value;
        if(age < 0 || age > 130) {
            this.setState({agerErrorMessage: 'Please enter a valid age.'})
        } else {
            this.setState({age: event?.target?.value, agerErrorMessage: null});
        }
    }

    handleGender(event) {
        this.setState({gender: event?.target?.value, genderErrorMessage: null});
    }

    handleVoterCardNumber(event) {
        this.setState({voterCardNumber: event?.target?.value, voterCardNumberErrorMessage: null});
    }

    handleWardNumber(event) {
        this.setState({wardNumber: event?.target?.value, wardNumberErrorMessage: null});
    }

    onCropChange = (crop) => {
        this.setState({ crop })
    }
    
    onCropComplete = (imageCroppedArea, imageCroppedAreaPixels) => {
        this.setState({imageCroppedArea, imageCroppedAreaPixels});
    }
    
    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }

    updateUploadProgress(value) {
        this.setState({setUploadProgress: value, profilePhotosErrorMessage: null});
    }

    handleProfilePhotos(event) {
        if(event.target.files.length > 0) {
            let dataUrlImage = URL.createObjectURL(event.target.files[0]);
            let fileNameElems = event.target?.files[0]?.name.split(".");
            fileNameElems = fileNameElems.splice(0,fileNameElems.length - 1);
            let fileName = fileNameElems.join(".") + ".jpeg";
                    
            this.setState({dataUrlImage, uploadedFileName: fileName, originalFileName: event.target?.files[0]?.name});
            document.querySelector('body').style.height = 'auto';
            document.querySelector('body').style.overflow = 'hidden';
            document.querySelector('body').classList.add('modal-open');
            var modalpopup = document.getElementById('imagecropmodel');
            modalpopup.classList.add('show');
            modalpopup.classList.remove('hide');
            modalpopup.style.display = 'block';
            modalpopup.setAttribute('aria-modal', true);
            modalpopup.setAttribute('role', 'dialog');
            modalpopup.removeAttribute('aria-hidden');
        } else {
            document.getElementById('profilephotos').title = this.state.originalFileName;
        }
    }


    async handleCroppedImage() {

        let imageSrc = this.state.dataUrlImage;
        let pixelCrop = this.state.imageCroppedAreaPixels;
        
        try{
            let imageBlob = await getCroppedImg(imageSrc, pixelCrop);
            let imageFile = new File([imageBlob], this.state.uploadedFileName,{type:'image/jpeg', lastModified:new Date().getTime()})
            profielImageUpload([imageFile], this.updateUploadProgress).then((data) => {
                this.setState({profilePhotos: data.file_urls, profileImageURL: data?.file_urls[0]?.url});
            }).catch((err) => {
                this.setState({profilePhotosErrorMessage: "Something went wrong. Please try uploading again."});
            });
            document.querySelector('body').style.overflow = '';
            document.querySelector('body').classList.remove('modal-open');
            var modalpopup = document.getElementById('imagecropmodel');
            modalpopup.classList.remove('show');
            modalpopup.classList.add('hide');
            modalpopup.style.display = 'none';
            modalpopup.removeAttribute('aria-modal');
            modalpopup.removeAttribute('role');
            modalpopup.setAttribute('aria-hidden', true);
        } catch(e) {
            console.log("Exception: ", e);
        }
        
    }

    handleModalClose() {
        document.querySelector('body').style.overflow = '';
        document.querySelector('body').classList.remove('modal-open');
        var modalpopup = document.getElementById('imagecropmodel');
        modalpopup.classList.remove('show');
        modalpopup.classList.add('hide');
        modalpopup.style.display = 'none';
        modalpopup.removeAttribute('aria-modal');
        modalpopup.removeAttribute('role');
        modalpopup.setAttribute('aria-hidden', true);
    }

    handleProfileImage(event) {
        const updateUploadProgress = (value) => {
            this.setState({setUploadProgress: value, profileImageErrorMessage: null});
        }
    
        const files = event.target.files;
        profielImageUpload(files, updateUploadProgress).then((data) => {
            this.setState({profileImage: data.file_urls[0]?.url, profilePhoto: data.file_urls});
        }).catch((err) => {
            this.setState({error: err});
        })
    }

    handleMemberShipSubmit() {
        const firstName = this.state.firstName;
        const fathersName = this.state.fathersName;
        const age = this.state.age;
        const gender = this.state.gender;
        const voterCardNumber = this.state.voterCardNumber;
        const profilePhotos = this.state.profilePhotos;
        const occupation = this.state.occupation;

        if(firstName == null || firstName == '') {
            this.setState({firstNameErrorMessage: "Please enter your name."});
            this.scrollTo("#firstname");
            return false;
        }

        if(fathersName == null || fathersName == '') {
            this.setState({fathersNameErrorMessage: "Please enter your father's / husband's name."});
            this.scrollTo("#fathersname");
            return false;
        }

        if(age == null || age == '') {
            this.setState({agerErrorMessage: "Please enter your age."});
            this.scrollTo("#age");
            return false;
        } else if(age > 130) {
            this.setState({agerErrorMessage: "Please enter a valid age."});
            this.scrollTo("#age");
            return false;
        }

        if(gender == null || gender == '') {
            this.setState({genderErrorMessage: "Please select a gender."});
            this.scrollTo("#gender");
            return false;
        }

        if(profilePhotos == null || profilePhotos == '') {
            this.setState({profilePhotosErrorMessage: "Please upload your photo."});
            this.scrollTo("#profilephotos");
            return false;
        }

        if((voterCardNumber == null || voterCardNumber == '') && (occupation != 'Student')) {
            this.setState({voterCardNumberErrorMessage: 'Please enter your Voter ID / EPIC number'});
            this.scrollTo("#votercardnumber");
            return false;
        }

            this.setState({isLoading: true});
            const finalData = {
                'first_name': this.state.firstName,
                'fathers_name': this.state.fathersName,
                'age': this.state.age,
                'gender': this.state.gender,
                'voter_card_number': this.state.voterCardNumber,
                'profile_image': this.state.profilePhotos,
            };

            updateSelfMembershipData(finalData).then((data) => {
                this.setState({submitData: data});
                this.props.resetHeaderProfile();
                this.props.navigate(`/`);
            }).catch((err) => {
                if(err?.response?.status == 400) {
                    this.setState({formError: err?.response?.data, isLoading: false});
                } else {
                    this.setState({formError: 'Something went wrong. Please try again.', isLoading: false});
                }
            });
    }

    componentDidMount() {
        getSelfDetails().then((data) => {
            let profileImage = data?.profile_image;
            let image = null;
            if(profileImage != null) {
                profileImage = profileImage.replaceAll("\"", "&quot;");
                profileImage = profileImage.replaceAll("'","\"");
                if(JSON.parse(profileImage) != null) {
                    let dataobj = JSON.parse(profileImage);
                    if(Array.isArray(dataobj)) {
                        image = dataobj[0].url;
                    }
                }
            }
            this.setState({
                firstName: data?.first_name,
                fathersName: data?.fathers_name,
                age: data?.age,
                gender: data?.gender,
                profileImageURL: image,
                profilePhotos: data?.profile_image,
                districtInputValueName: data?.district?.name,
                districtInputValueId: data?.district?.id,
                constituencyInputValueName: data?.assembly_constituency?.name,
                constituencyInputValueId: data?.assembly_constituency?.id,
                blockInputValueName: data?.block?.name,
                blockInputValueId: data?.block?.id,
                grampanchayatInputValueName: data?.gram_panchayat?.name,
                grampanchayatInputValueId: data?.gram_panchayat?.id,
                wardNumber: data?.ward_number,
                isUserStudent: data?.occupation,
                occupation: data?.occupation,
                voterCardNumber: data?.voter_card_number,
                studentCollegeName: data?.student_college_name,
                mobileNumber: data?.mobile_number,
                whatsAppNumber: data?.whatsapp_mobile_number
            });
            getAssemblyConstituencyList(data?.district?.id).then((data) => {
                this.setState({constituencyList: data});
            }).catch((err) => {
                this.setState({error: err});
            })

            getBlockList(data?.assembly_constituency?.id).then((data) => {
                this.setState({blockList: data});
            }).catch((err) => {
                this.setState({error: err});
            })

            getGramPanchayatList(data?.block?.id).then((data) => {
                this.setState({grampanchayatList: data});
            }).catch((err) => {
                this.setState({error:err});
            })

        }).catch((err) => {
            this.setState({error: err});
        })
    }
    
    render() {
        return(
            <>
                <div className="container pt-5">
                    <div className="card w-50 w-sm-100 mx-auto">
                        <div className="card-header">
                            <h3 className="text-center">Edit Profile</h3>
                        </div>
                        <div className="card-body">
                            <form action="#" method="post">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="name">Name <span className="text-danger fw-600">*</span></label>
                                            <input type="text" name="firstname" id="firstname" className="form-control" placeholder="Name" onChange={this.handleFirstName} value={this.state.firstName ? this.state.firstName : ''} />
                                            <span className="text-danger fw-600">{ this.state.firstNameErrorMessage ? this.state.firstNameErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="fathersname">Father / Husband Name <span className="text-danger fw-600">*</span></label>
                                            <input type="text" name="fathersname" id="fathersname" className="form-control" placeholder="Father / Husband Name" value={this.state.fathersName ? this.state.fathersName : ''} onChange={this.handleFathersName} />
                                            <span className="text-danger fw-600">{ this.state.fathersNameErrorMessage ? this.state.fathersNameErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="age">Age <span className="text-danger fw-600">*</span></label>
                                            <input type="number" name="age" id="age" className="form-control" placeholder="Age" value={this.state.age ? this.state.age : ''} minLength={3} maxLength={3} onChange={this.handleAge} />
                                            <span className="text-danger fw-600">{ this.state.agerErrorMessage ? this.state.agerErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8">
                                        <div className="form-group">
                                            <label htmlFor="gender">Gender <span className="text-danger fw-600">*</span></label>
                                            <select name="gender" id="gender" className="form-select" onChange={this.handleGender} >
                                                <option value="" selected={this.state.gender === '' ? 'selected' : ''}>Select</option>
                                                <option value="M" selected={this.state.gender === 'M' ? 'selected' : ''} >Male</option>
                                                <option value="F" selected={this.state.gender === 'F' ? 'selected' : ''}>Female</option>
                                                <option value="O" selected={this.state.gender === 'O' ? 'selected' : ''}>Other</option>
                                            </select>
                                            <span className="text-danger fw-600">{ this.state.genderErrorMessage ? this.state.genderErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="profilephotos">Upload Photo <span className="text-danger fw-600">*</span></label>
                                            <div className="row">
                                            <div className="col-md-9 col-12 order-1 order-md-0">
                                                <input type="file" name="profilephotos" id="profilephotos" className="form-control d-none" onChange={this.handleProfilePhotos} capture accept="image/*"/>
                                                <label htmlFor="profilephotos" className="btn btn-white border w-100 text-left ps-0"><span className="border-right h-100 my-5 profile-choose-file-label">Choose File</span> <span className="ps-2 original-file-name">{ this.state.originalFileName }</span> </label>
                                                <span className="text-danger fw-600">{ this.state.profilePhotosErrorMessage ? this.state.profilePhotosErrorMessage : '' }</span>
                                            </div>
                                            <div className="col-12 col-md-3 order-0 pb-3 pb-md-0 order-md-1">
                                                <div className="form-group mb-0">
                                                    <label htmlFor="profilephotos" className="m-0 d-unset">
                                                        <div className="profile-image-preview cursor-pointer" htmlFor="profilephotos">
                                                            <img src={ this.state.profileImageURL ? mediaURL + this.state.profileImageURL : profileImagePlaceholder } alt="" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-12 ${this.state.occupation == `Student` ? `d-none` : `d-block`}`}>
                                        <div className="form-group">
                                            <label htmlFor="votercardnumber">Voter ID / EPIC Number <span className="text-danger fw-600">*</span></label>
                                            <input type="text" name="votercardnumber" id="votercardnumber" className="form-control" placeholder="Voter Card Number" value={this.state.voterCardNumber ? this.state.voterCardNumber : ''} onChange={this.handleVoterCardNumber} />
                                            <span className="text-danger fw-600">{ this.state.voterCardNumberErrorMessage ? this.state.voterCardNumberErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className={`form-group text-center`} >
                                            <input type="button" value={`${this.state.isLoading ? 'Loading...' : 'Submit'}`} className="btn btn-success fs-5" onClick={this.handleMemberShipSubmit} />
                                            <span className="text-danger fw-600 d-block">{this.state.formError ? this.state.formError : ''}</span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="imagecropmodel" aria-hidden="true" aria-labelledby="imagecropmodelLabel" tabindex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="imagecropmodelLabel">Please crop the image that set in the profile.</h1>
                            <button type="button" className="btn-close" onClick={ this.handleModalClose } data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body imagecrop-body">
                            <Cropper image={this.state.dataUrlImage} crop={this.state.crop} zoom={this.state.zoom} aspect={this.state.aspect} onCropChange={this.onCropChange} onCropComplete={this.onCropComplete} onZoomChange={this.onZoomChange} />
                        </div>
                        <div className="modal-footer">
                            <img id="cropped-image-preview"></img>
                            <button className="btn btn-primary" onClick={ this.handleCroppedImage }>Save</button>
                        </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withParams(SelfUserProfileEdit);